/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import React from 'react'
import { ActiveProjectProvider } from '@utilities'


export const wrapRootElement = ({ element }) => (
  <ActiveProjectProvider>
    {element}
  </ActiveProjectProvider>
)

