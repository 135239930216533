// export default (input: string, type?: 'images' | 'files') => {
//   const regex = new RegExp(`cdn.sanity.io\/(images|files)\/${process.env.GATSBY_SANITY_PROJECT_ID}\/${process.env.GATSBY_SANITY_DATASET}`)
//   return input.replace(regex, type !== 'files' ? process.env.GATSBY_IMGIX_IMAGE_PATH! : process.env.GATSBY_IMGIX_FILE_PATH!)
// }
// 

export default (input: string, type?: 'images' | 'files') => {
  const regex = new RegExp(`cdn.sanity.io\/(images|files)\/${process.env.GATSBY_SANITY_PROJECT_ID}\/${process.env.GATSBY_SANITY_DATASET}`)
  return input.replace(regex, type === 'files' ? process.env.GATSBY_IMGIX_FILE_PATH! : process.env.GATSBY_IMGIX_IMAGE_PATH!)
}

